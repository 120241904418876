<template>
  <div>
    <!--begin::Box-->
    <div class="row">
      <div class="col-xxl-3">
        <StatsWidget7
          icon="media/svg/icons/Shopping/Safe.svg"
          icon-class="danger"
          :text="$t('DASHBOARD.AMOUNT_TRX')"
          :count="today_transaction_amount"
        ></StatsWidget7>
      </div>
      <div class="col-xxl-3">
        <StatsWidget7
          icon="media/svg/icons/Shopping/Wallet.svg"
          icon-class="danger"
          :text="$t('DASHBOARD.AMOUNT_WITHDRAW')"
          :count="today_withdrawal_amount"
        ></StatsWidget7>
      </div>
      <div class="col-xxl-3">
        <StatsWidget7
          icon="media/svg/icons/General/User.svg"
          icon-class="warning"
          :text="$t('DASHBOARD.NUMBER_TRX')"
          :count="today_transaction_number"
        ></StatsWidget7>
      </div>
      <div class="col-xxl-3">
        <StatsWidget7
          icon="media/svg/icons/Shopping/Money.svg"
          icon-claxss="info"
          :text="$t('DASHBOARD.BALANCE')"
          :count="balance"
        ></StatsWidget7>
      </div>
    </div>
    <!--end::Box-->

    <!-- Start List  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 pt-3 pb-0 mt-3 page-title">
        <h3>
          {{ $t("MENU.RECENT_TRANSACTION") }} &nbsp;<b-button
            variant="outline-primary"
            size="sm"
            @click="$router.push('/transaction')"
            >{{ $t("TABLE.VIEW_ALL") }}</b-button
          >
        </h3>
        <div>
          <b-button
            variant="primary"
            size="sm"
            v-if="items.length !== 0"
            @click="exportMethod()"
            >{{ $t("TRANSACTION.EXPORT") }}</b-button
          >
        </div>
      </div>
      <div class="card-body pb-0">
        <!-- <b-table class="bg-white" striped hover :items="items"> -->
        <b-table
          class="bg-white"
          striped
          hover
          :fields="fields"
          :items="items"
          responsive
        >
          <!-- Set No Data when none of item -->
          <template #bottom-row="items" v-if="items.length === 0">
            <b-td :colspan="fields.length" class="text-center">
              {{ $t("TABLE.NO_DATA") }}
            </b-td>
          </template>
          <template #cell(sn)="data">
            <div v-if="data.item.sn">
              ...{{ data.item.sn.substr(data.item.sn.length - 8) }}
              <a href="javascript:void(0)" @click="copy(data.item.sn)">
                <img src="@/assets/img/copy.png" width="15px" />
              </a>
            </div>
          </template>
          <template #cell(sender_address)="data">
            <div v-if="data.item.sender_address">
              ...{{
                data.item.sender_address.substr(
                  data.item.sender_address.length - 8
                )
              }}
              <a
                href="javascript:void(0)"
                @click="copy(data.item.sender_address)"
              >
                <img src="@/assets/img/copy.png" width="15px" />
              </a>
            </div>
          </template>
          <template #cell(type)="data">
            {{ $t(`MENU.${data.item.type}`) }}
          </template>

          <template #cell(date_time)="data">
            {{ data.item.date_time | moment("DD-MM-YYYY HH:mm:ss") }}
          </template>

          <template #cell(status)="data">
            {{ $t(`TABLE.${data.item.status}`) }}
          </template>
        </b-table>
      </div>
    </div>
    <!-- End List  -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  EXPORT_DATA,
  GET_STATISTIC,
  GET_STAT_LIST
} from "@/core/services/store/dashboard.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import globalMixin from "@/core/plugins/mixins.js";

export default {
  mixins: [globalMixin],
  name: "dashboard",
  components: {
    StatsWidget7
  },
  data() {
    return {
      today_transaction_amount: 0,
      today_withdrawal_amount: 0,
      today_transaction_number: 0,
      balance: 0,
      items: [],
      pagination: {
        limit: 50,
        page: 1,
        totalRow: 0
      },
      fields: [
        {
          key: "sn",
          label: this.$t("TABLE.TRX_ID"),
          tdClass: "align-middle break-column",
          thClass: "text-center"
        },
        {
          key: "sender_address",
          label: this.$t("TABLE.SENDER_ADDRESS"),
          tdClass: "align-middle break-column",
          thClass: "text-center"
        },
        {
          key: "token_type",
          label: this.$t("TABLE.TOKEN_TYPE"),
          tdClass: "align-middle break-column text-center",
          thClass: "text-center"
        },
        {
          key: "token_name",
          label: this.$t("TABLE.TOKEN_NAME"),
          tdClass: "align-middle break-column text-center",
          thClass: "text-center"
        },
        {
          key: "network",
          label: this.$t("TABLE.NETWORK"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "amount",
          label: this.$t("TABLE.AMOUNT"),
          tdClass: "text-right align-middle",
          thClass: "text-center"
        },
        {
          key: "type",
          label: this.$t("TABLE.TYPE"),
          tdClass: "text-right align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "date_time",
          label: this.$t("TABLE.DATE_TIME"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "status",
          label: this.$t("TABLE.STATUS"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getStatistic();
    this.getStatTable();
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    getStatistic(page) {
      if (page) {
        this.pagination.page = page;
      }

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_STATISTIC)
        .then((e) => {
          this.today_transaction_amount = e.data.today_transaction_amount;
          this.today_withdrawal_amount = e.data.today_withdrawal_amount;
          this.today_transaction_number = e.data.today_transaction_number;
          this.balance = e.data.balance;
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    getStatTable() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_STAT_LIST)
        .then((e) => {
          this.items = e.result;
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    exportMethod() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(EXPORT_DATA)
        .then((e) => {
          const url = window.URL.createObjectURL(new Blob([e]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "dashboard-recent-transaction.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
  }
};
</script>
